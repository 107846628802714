<template>
  <b-card>
    <form @submit.prevent="newItem" id="new-item">
      <div class="row">
        <div class="col-md-6">
          <b-form-group>
            <label for="title"> عنوان <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-input id="title" name="title" :disabled="disabled"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="اسلاگ" label-for="slug">
            <b-form-input id="slug" name="slug" :disabled="disabled"></b-form-input>
          </b-form-group>
        </div>
      </div>
      <b-form-group>
        <label for="body"> متن <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
        <b-form-textarea id="body" rows="5" v-model="body" :disabled="disabled"></b-form-textarea>
      </b-form-group>

      <div class="form-group">
        <label class="custom-switch">
          <input type="checkbox" name="status" class="custom-switch-input" v-model="selected_status"
            :disabled="disabled">
          <span class="custom-switch-indicator"></span>
          <span class="custom-switch-description">وضعیت</span>
        </label>
      </div>
      <input type="hidden" name="meta_desc" value="">
      <input type="hidden" name="meta_tag" value="">
      <button class="btn btn-success" type="submit" :disabled="disabled">ثبت صفحه</button>
    </form>
  </b-card>
</template>
<script>
  import mixins from '../mixins/mixins'

  export default {
    mixins: [mixins],
    data() {
      return {
        url: '/api/admin/page',
        selected_status: '1',
        title: 'ثبت صفحه',
        body:''
      }
    },
    mounted() {
      
    },
    created() {
      this.loadItems()
      this.loadCkeditor()
    },
    methods: {
      newItem() {
        this.disabled = true
        const body = window.CKEDITOR.instances.body.getData()
        let form = document.getElementById('new-item')
        let formData = new FormData(form)
        formData.append('body',body)
        this.$axios.post(this.$root.baseUrl + this.url, formData)
          .then(response => {
            this.$root.success_notification('صفحه با موفقیت ثبت شد')
            this.$router.push('/admin/page')
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
    }
  }
</script>